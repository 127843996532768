import React from 'react'
import Styled from 'styled-components'

const Text = Styled.div`
  display: flex;
  flex-direction: column;
`
const StyledFooter = Styled.footer`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  font-size: ${9 / 16}rem;
  justify-content: space-around;
  padding-top: ${150 / 16}rem;
  width: 100%;
  @media screen and (min-width: 576px) {
    font-size: ${14 / 16}rem;
    padding-left: ${26 / 16}rem;
    padding-right: ${26 / 16}rem;
  }
  @media screen and (min-width: 768px) {
    font-size: ${18 / 16}rem;
    padding: ${150 / 16}rem 0 ${26 / 16}rem;
  }  
`

const Footer = () => (
  <StyledFooter>
    <Text>
      <div>1260 Yonge St, 2nd Floor,</div>
      <div>Toronto ON, M4T 1W5</div>
    </Text>
    <Text style={{ textAlign: 'center' }}>
      <div>© {new Date().getFullYear()} Noble Caplan Abrams Talent Management Agency,</div>
      <div>All Rights Reserved</div>
    </Text>
    <Text>
      <div>O: 416.920.5385</div>
      <div>F: 416.920.6343</div>
    </Text>
  </StyledFooter>
)

export default Footer
