import React from 'react';
import { connect } from 'react-redux'
import { Link } from 'gatsby';
import Styled from 'styled-components';
import { toggleNav } from '../../store/site/actions'

const StyledNav = Styled.nav`
  transition: all .2s ease-in-out;
  width: 80%;
  height: 100%;
  position: absolute;
  flex-shrink: 0;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #632A6D;
  z-index: 9;
  left: ${props => props.state ? '0' : '-100%'};
  @media screen and (min-width: 576px) {
    width: 100%;
    position: static;
    flex-direction: row;
    justify-content: center;
    height: ${43/16}rem;
  }
  @media screen and (min-width: 768px) {
    padding: 0 ${90/16}rem;
  }
`
const StyledLink = Styled(Link)`
  position: relative;
  text-transform: uppercase;
  font-size: ${29/16}rem;
  margin-left: ${31/16}rem;
  margin-right: ${21/16}rem;
  color: #A28DAD;
  font-weight: bold;
  @media screen and (min-width: 576px) {
    font-weight: 400;
    color: white;
    margin-left: 0;
    margin-right: 0;
    font-size: ${16/16}rem;

  }
  @media screen and (min-width: 992px) {
    font-size: ${21/16}rem;

  }  
`;
const Patty = Styled.div`
  width: 31px;
  height: 4px;
`
const Hamburger = Styled.div`
  cursor: pointer;
  z-index: 10;
  position: absolute;
  top: 15px;
  left: 21px;
  margin: 5px;
  min-height: 26px;
  min-width: 31px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  ${Patty} {
    position: ${props => props.state ? 'absolute' : 'static'};
    left: 0;
    top: 10px;

    background-color: ${props => props.state ? 'white' : '#632A6D'};
    transform: ${props => props.state ? 'rotate(45deg)' : 'rotate(0deg)'};
    &:nth-child(2) {
      display: ${props => props.state ? 'none' : 'block'};
    }
    &:nth-child(3) {
      transform: ${props => props.state ? 'rotate(-45deg)' : 'rotate(0deg)'};
    }
  }
  @media screen and (min-width: 576px) {
    display: none;
  }
`
const NavInner = Styled.div`
  width: 100%;
  height: 60%;
  max-width: 1400px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  margin-top: 40px;
  @media screen and (min-width: 576px) {
    margin-top: 0;
    height: 60%;
    align-items: center;
    flex-direction: row;
    height: ${43/16}rem;
  }
  @media screen and (min-width: 1050px) {
    padding: 0 ${90/16}rem;
  }
`
const Overlay = Styled.div`
  background: rgba(91, 55, 109, 0.20);
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 8;
  display: ${props => props.state ? 'block' : 'none'};
`
const Navigation = (props) => (
  <>
    <Overlay state={props.navState} onClick={props.navStateToggler}></Overlay>
    <Hamburger state={props.navState} onClick={props.navStateToggler}>
      <Patty />
      <Patty />
      <Patty />
    </Hamburger>
    <StyledNav state={props.navState}>
      <NavInner>
        <StyledLink activeClassName="activeLink" onClick={props.navState ? props.navStateToggler : null} to="/talent-submissions">Talent Submissions</StyledLink>
        <StyledLink activeClassName="activeLink" onClick={props.navState ? props.navStateToggler : null} to="/voice-reels-demos">Voice Reels & Demos</StyledLink>
        {/* <StyledLink activeClassName="activeLink" onClick={props.navState ? props.navStateToggler : null} to="/news">News</StyledLink>   */}
        <StyledLink activeClassName="activeLink" onClick={props.navState ? props.navStateToggler : null} to="/staff-directory">Staff Directory</StyledLink>  
      </NavInner>
    </StyledNav>
  </>
)

const mapStateToProps = ({
  site
}) => ({
  navState: site.navState
})

const mapDispatchToProps = (dispatch) => ({
  navStateToggler: () => dispatch(toggleNav()),
})

export default connect(
  mapStateToProps, 
  mapDispatchToProps
)(Navigation)