import React from 'react'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'
import Styled from 'styled-components'
import { Location } from '@reach/router'

import Header from './header'
import Footer from './footer'
import Navigation from './navigation'

import './layout.css'
import Logo from '../../icons/logo.svg'
import icon48 from '../../images/icon-48x48.png'

const Main = Styled.div`
  flex: 1 0 auto;
  display: flex;
  width: 100%;
  max-width: 1400px;
  @media screen and (min-width: 768px) {
    padding: 0 ${13 / 16}rem;
  }
  @media screen and (min-width: 992px) {
    padding: 0 ${90 / 16}rem;
  }  
`
export const NCALogo = Styled(Logo)`
  width: 120px;
  height: 190px;
  @media screen and (min-width: 768px) {
    width: 205px;
    height: 320px;
  }
  @media screen and (min-width: 992px) {
    height: auto;
    width: auto;
  }
`
export const LogoContainer = Styled.div`
  display: ${props => (props.show ? 'block' : 'none')};
  margin-top: 60px;
  position: absolute;
  left: 50%;
  margin-left: -60px;

  @media screen and (min-width: 768px) {
    position: static;
    display: block;
    margin-left: 0;
    margin-top: ${35 / 16}rem;
    margin-right: ${25 / 16}rem;
  }
  @media screen and (min-width: 991px) {
    margin-top: ${94 / 16}rem;
    margin-left: ${39 / 16}rem;
    margin-right: 0;
  }
`
const Wrapper = Styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`
const Layout = ({ children, nav }) => {
  let navStyle = nav ? 'navIsOpen' : 'navIsClosed'
  return (
    <>
      <Helmet
        link={[{ rel: 'shortcut icon', type: 'image/png', href: `${icon48}` }]}
        title="Noble Caplan Abrams"
      >
        <html lang="en" />
        <body className={navStyle} />
      </Helmet>
      <Wrapper nav={nav}>
        <Header />
        <Navigation />
        <Main>
          <Location>
            {({ location }) => (
              <LogoContainer show={location.pathname === '/'}>
                <NCALogo />
              </LogoContainer>
            )}
          </Location>

          {children}
        </Main>
        <Footer />
      </Wrapper>
    </>
  )
}

const mapStateToProps = ({ site }) => ({
  nav: site.navState,
})

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Layout)
