import React from 'react'
import Styled from 'styled-components';
import { Link } from 'gatsby';

const StyledLink = Styled(Link)`
  text-decoration: none;
  color: #632A6D;
`;
const StyledHeader = Styled.header`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  padding: 13px 15px;
  text-align: right;
  margin-bottom: 40px;
  align-self: flex-end;
  width: 100%;
  max-width: 1400px;
  @media screen and (min-width: 576px) {
    margin-bottom: 0;
    text-align: left;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    height: ${110/16}rem;
    padding: 0 ${45/16}rem;
    align-self: center;
  }
  @media screen and (min-width: 992px) {
    height: ${154/16}rem;
    padding: 0 ${90/16}rem;
  }
`;
const Title = Styled.h1`
  font-size: 18px;
  font-family: Roboto;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 7px;
  @media screen and (min-width: 576px) {
    margin: 0;
    letter-spacing: 9px;
    font-size: ${24/16}rem;
  }
  @media screen and (min-width: 992px) {
    font-size: ${32/16}rem;
    letter-spacing: 15.45px;
  }
  `;
const SubTitle = Styled.h2`
  font-family: 'Libre Baskerville';
  font-style: italic;
  font-weight: 400;
  margin: 0;
  font-size: 12px;
  @media screen and (min-width: 576px) {
    font-size: ${18/16}rem;
  }
  @media screen and (min-width: 992px) {
    letter-spacing: 1.72px;
    font-size: ${28/16}rem;
  }
`;
const Header = () => (
  <StyledHeader>
    <Title>
      <StyledLink to="/">Noble <span style={{color: '#E84640'}}>C</span>aplan <span style={{color: '#F17A30'}}>A</span>brams</StyledLink>
    </Title>
    <SubTitle>
      Talent Management
    </SubTitle>
  </StyledHeader>
);

export default Header;
